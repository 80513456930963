.is-sidebar-open .main-sidebar,.sidebar-panel {
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.sidebar-panel {
  --tw-translate-x: -100%;
  --tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transform: translate3d(var(--tw-translate-x),var(--tw-translate-y),0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-delay: .15s;
  transition-duration: .15s;
  transition-duration: .25s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-timing-function: cubic-bezier(.4,0,1,1);
  width: calc(var(--main-sidebar-width) + var(--sidebar-panel-width));
  z-index: 30
}

.dark .sidebar-panel,.sidebar-panel {
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)
}

.dark .sidebar-panel {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000
}

@media (min-width: 768px) {
  .sidebar-panel {
    transition-delay:unset
  }
}

.is-sidebar-open .sidebar-panel {
  --tw-translate-x: 0px;
  transition-timing-function: cubic-bezier(0,0,.2,1)
}

.is-sidebar-open .sidebar-panel,.sidebar-panel-min {
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.sidebar-panel-min {
  --tw-translate-x: -100%;
  --tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  flex-shrink: 0;
  height: 100%;
  position: fixed;
  transform: translate3d(var(--tw-translate-x),var(--tw-translate-y),0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: .15s;
  transition-duration: .25s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-timing-function: cubic-bezier(.4,0,1,1);
  width: var(--sidebar-panel-min-width);
  z-index: 40
}

.dark .sidebar-panel-min,.sidebar-panel-min {
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)
}

.dark .sidebar-panel-min {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000
}

@media (min-width: 768px) {
  .sidebar-panel-min {
    --tw-translate-x:var(--main-sidebar-width);
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}

.is-sidebar-open .sidebar-panel-min {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
