/**
 * Contains all imports of external CSS libs (.css files) that must be injected in all Next.js pages.
 *
 * This approach is preferred over importing them all one by one within the _app.tsx file, because it's easier to maintain.
 *
 * Also, this file is being imported by both "src/pages/_app.tsx" and ".storybook/preview.js",
 * so that global 3rd party CSS are included when previewing components, too.
 */
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;400;600;700;800&family=Sigmar+One&display=swap');

@import "public/static/styles/lineone/backdrop.css";
@import "public/static/styles/lineone/badge.css";
@import "public/static/styles/lineone/button.css";
@import "public/static/styles/lineone/card.css";
@import "public/static/styles/lineone/form-checkbox.css";
@import "public/static/styles/lineone/form-switch.css";
@import "public/static/styles/lineone/line-clamp.css";
@import "public/static/styles/lineone/main-content.css";
@import "public/static/styles/lineone/main-sidebar.css";
@import "public/static/styles/lineone/menu-toggle.css";
@import "public/static/styles/lineone/nav-header.css";
@import "public/static/styles/lineone/sidebar.css";
@import "public/static/styles/lineone/sidebar-panel.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: blue;
  text-decoration: underline;
  overflow-wrap: break-word;
}

* {
  box-sizing: border-box;
}

/*#__next {*/
/*  background-color: #f8fafc;*/
/*  flex-grow: 1;*/
/*  !*display: flex;*!*/
/*  !*min-height: 100vh;*!*/
/*  min-height: calc(var(--vh, 1vh)*100);*/
/*  border: 0 solid #e5e7eb;*/
/*  box-sizing: border-box;*/
/*}*/

[data-reactdiv-element="nextImageWrapper"] > span {
  position: unset !important;
}

.min-h-100vh {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh)*100)
}
