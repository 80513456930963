.menu-toggle span {
  --tw-translate-x: 0px;
  background-color: currentColor;
  height: 2px;
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: .15s;
  transition-duration: .25s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  width: 1.25rem
}

.menu-toggle span:nth-child(2) {
  width: .75rem
}

.menu-toggle.active span {
  --tw-translate-x: -0.5rem;
  margin-left: .5rem;
  width: 11px
}

.menu-toggle.active span,.menu-toggle.active span:first-child {
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.menu-toggle.active span:first-child {
  --tw-rotate: -45deg
}

.menu-toggle.active span:nth-child(2) {
  display: none
}

.menu-toggle.active span:nth-child(3) {
  --tw-rotate: 45deg
}

.main-sidebar,.menu-toggle.active span:nth-child(3) {
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
