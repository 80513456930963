.btn {
  align-items: center;
  border-radius: .5rem;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  letter-spacing: .025em;
  padding: .5rem 1.25rem;
  text-align: center;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1)
}

.btn,.btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.btn:disabled {
  pointer-events: none
}
