nav.header {
  /*border-bottom-width: 1px;*/
  /*border-color: #e9eef5;*/
  display: flex;
  height: 61px;
  position: fixed;
  right: 0;
  transition-duration: .15s;
  transition-duration: .25s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  width: 100%;
  z-index: 20;
  box-shadow: 0px 0px 10px 1px rgba(255, 255, 255, 0.10);
}

.dark nav.header {
  /*border-color: #26334d*/
}

@media (min-width: 768px) {
  nav.header {
    width:calc(100% - var(--main-sidebar-width))
  }
}

nav.header:before {
  --tw-content: "";
  background-color: #fff;
  content: var(--tw-content);
  height: 100%;
  left: calc(-100vw - -100%);
  position: absolute;
  width: calc(100vw - 100%)
}

.dark nav.header:before {
  background-color: #222e45
}

nav.header .header-container {
  padding-left: var(--margin-x);
  padding-right: var(--margin-x);
  transition-duration: .15s;
  transition-duration: .25s;
  transition-property: padding,width;
  transition-timing-function: cubic-bezier(.4,0,.2,1)
}

@media (min-width: 768px) {
  .has-min-sidebar nav.header {
    width:calc(100% - var(--main-sidebar-width) - var(--sidebar-panel-min-width))
  }
}

@media (min-width: 1280px) {
  .is-sidebar-open nav.header {
    width:calc(100% - var(--main-sidebar-width) - var(--sidebar-panel-width))
  }
}

.is-header-blur nav.header .header-container {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

@supports ((-webkit-backdrop-filter: blur(0)) or (backdrop-filter:blur(0))) or (-webkit-backdrop-filter:blur(0)) {
  .is-header-blur nav.header .header-container {
    background-color:hsla(0,0%,100%,.9)
  }

  .dark .is-header-blur nav.header .header-container {
    background-color: rgba(34,46,69,.9)!important
  }
}
