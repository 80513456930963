.main-content {
  display: grid;
  grid-template-columns: repeat(1,minmax(0,1fr));
  margin-top: 60px;
  place-content: start;
  transition-duration: .15s;
  transition-duration: .25s;
  transition-property: width,margin-left,margin-right,padding-left,padding-right;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-timing-function: cubic-bezier(.4,0,1,1)
}

@media print {
  .main-content {
    margin: 0
  }
}

@media (min-width: 768px) {
  .main-content {
    margin-left:var(--main-sidebar-width)
  }

  .has-min-sidebar .main-content {
    margin-left: calc(var(--main-sidebar-width) + var(--sidebar-panel-min-width))
  }
}

.is-sidebar-open .main-content {
  transition-timing-function: cubic-bezier(0,0,.2,1)
}

@media (min-width: 1280px) {
  .is-sidebar-open .main-content {
    margin-left:calc(var(--main-sidebar-width) + var(--sidebar-panel-width))
  }
}
