.line-clamp-1 {
  -webkit-line-clamp: 1
}

.line-clamp-1,.line-clamp-2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden
}

.line-clamp-2 {
  -webkit-line-clamp: 2
}

.line-clamp-3 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden
}
