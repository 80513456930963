.badge,.tag {
  align-items: center;
  border-radius: .25rem;
  display: inline-flex;
  font-family: Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-size: .75rem;
  font-weight: 500;
  justify-content: center;
  letter-spacing: .025em;
  line-height: 1rem;
  line-height: 1;
  padding: .375rem .5rem;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  vertical-align: baseline
}

.tag {
  cursor: pointer
}
