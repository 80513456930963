.form-checkbox,.form-radio,.form-switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.form-checkbox,.form-checkbox:focus,.form-radio,.form-radio:focus,.form-switch,.form-switch:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.form-checkbox,.form-radio,.form-switch {
  print-print-color-adjust: exact
}

.form-switch {
  --thumb-border: 2px
}

.form-switch:before {
  content: var(--tw-content);
  height: calc(100% - var(--thumb-border)*2);
  left: var(--thumb-border);
  position: absolute;
  top: var(--thumb-border);
  width: calc(50% - var(--thumb-border)*2)
}

.form-switch:checked:before {
  --tw-translate-x: calc(100% + var(--thumb-border)*2);
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.form-switch:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 #0000)
}

.form-switch.is-outline:before {
  content: var(--tw-content);
  width: calc(50% - var(--thumb-border)*2 - 1px)
}

.form-switch.is-outline:checked:before {
  --tw-translate-x: calc(100% + var(--thumb-border)*2 + 2px);
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
