.card {
  --tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  background-color: #251b43;
  border-radius: .5rem;
  color: #64748b;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  overflow-wrap: break-word;
  position: relative
}

.card,.dark .card {
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)
}

.dark .card {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  background-color: #26334d;
  color: #a3adc2
}

@media print {
  .card {
    border-width: 1px
  }
}
