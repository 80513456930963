*,:after,:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  /*--tw-pan-x: ;*/
  /*--tw-pan-y: ;*/
  /*--tw-pinch-zoom: ;*/
  --tw-scroll-snap-strictness: proximity;
  /*--tw-ordinal: ;*/
  /*--tw-slashed-zero: ;*/
  /*--tw-numeric-figure: ;*/
  /*--tw-numeric-spacing: ;*/
  /*--tw-numeric-fraction: ;*/
  /*--tw-ring-inset: ;*/
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  /*--tw-blur: ;*/
  /*--tw-brightness: ;*/
  /*--tw-contrast: ;*/
  /*--tw-grayscale: ;*/
  /*--tw-hue-rotate: ;*/
  /*--tw-invert: ;*/
  /*--tw-saturate: ;*/
  /*--tw-sepia: ;*/
  /*--tw-drop-shadow: ;*/
  /*--tw-backdrop-blur: ;*/
  /*--tw-backdrop-brightness: ;*/
  /*--tw-backdrop-contrast: ;*/
  /*--tw-backdrop-grayscale: ;*/
  /*--tw-backdrop-hue-rotate: ;*/
  /*--tw-backdrop-invert: ;*/
  /*--tw-backdrop-opacity: ;*/
  /*--tw-backdrop-saturate: ;*/
  /*--tw-backdrop-sepia:*/
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  /*--tw-pan-x: ;*/
  /*--tw-pan-y: ;*/
  /*--tw-pinch-zoom: ;*/
  --tw-scroll-snap-strictness: proximity;
  /*--tw-ordinal: ;*/
  /*--tw-slashed-zero: ;*/
  /*--tw-numeric-figure: ;*/
  /*--tw-numeric-spacing: ;*/
  /*--tw-numeric-fraction: ;*/
  /*--tw-ring-inset: ;*/
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59,130,246,.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  /*--tw-blur: ;*/
  /*--tw-brightness: ;*/
  /*--tw-contrast: ;*/
  /*--tw-grayscale: ;*/
  /*--tw-hue-rotate: ;*/
  /*--tw-invert: ;*/
  /*--tw-saturate: ;*/
  /*--tw-sepia: ;*/
  /*--tw-drop-shadow: ;*/
  /*--tw-backdrop-blur: ;*/
  /*--tw-backdrop-brightness: ;*/
  /*--tw-backdrop-contrast: ;*/
  /*--tw-backdrop-grayscale: ;*/
  /*--tw-backdrop-hue-rotate: ;*/
  /*--tw-backdrop-invert: ;*/
  /*--tw-backdrop-opacity: ;*/
  /*--tw-backdrop-saturate: ;*/
  /*--tw-backdrop-sepia:*/
}

:root {
  --margin-x: 1rem;
  --main-sidebar-width: 4.5rem;
  --sidebar-panel-width: 230px;
  --sidebar-panel-min-width: 64px
}

@media (min-width: 768px) {
  :root {
    --margin-x:1.5rem
  }
}

@media (min-width: 1024px) {
  :root {
    --main-sidebar-width:5rem;
    --sidebar-panel-width: 240px
  }
}

@media (min-width: 1280px) {
  :root {
    --margin-x:4rem
  }

  .has-min-sidebar {
    --margin-x: 2.5rem
  }

  .is-sidebar-open {
    --margin-x: 1.5rem
  }
}

.fa-palette:before {
  content: "\f53f"
}


.wp-block-image {
  justify-content: center;
  display: flex;
}
