.main-sidebar,.menu-toggle.active span:nth-child(3) {
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.main-sidebar {
  --tw-translate-x: -100%;
  flex-shrink: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transform: translate3d(var(--tw-translate-x),var(--tw-translate-y),0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: .2s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-timing-function: cubic-bezier(.4,0,1,1);
  width: var(--main-sidebar-width);
  z-index: 40
}

@media (min-width: 768px) {
  .main-sidebar {
    --tw-translate-x:0px;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    z-index: 60
  }
}

.is-sidebar-open .main-sidebar {
  --tw-translate-x: 0px
}

.is-sidebar-open .main-sidebar,.sidebar-panel {
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
